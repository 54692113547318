<template>
    <HeadeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
        :opacity="header_opacity"  v-on="$listeners" v-bind="$attrs"  >
        <slot name="left" slot="left" v-if="$slots.left" />
        <slot name="right" slot="right" v-if="$slots.right" />
    </HeadeBar>
</template>
<script>
import HeadeBar from '@/components/app/headBar';
export default {
    props: {
        box: {
            type: String,
            default: 'window'
        },
        title: {
            type: String,
            default() {
                return this.$route.meta.title || ''
            }
        },
        baseColor:{
            type: String,
            default: '#fff'
        },
        scrollColor:{
            type: String,
            default: '#333'
        },
        scrollBG:{
            type: String,
            default: '#fff'
        }
    },
    components: {
        HeadeBar,
    },
    data() {
        return {
            header_color: this.baseColor,
            header_title: '',
            header_background: '#ffffff00',
            header_opacity: 1
        }
    },
    beforedestroy() {
        this.$dom && this.$dom.removeEventListener('scroll', this.scrollFunction)
    },
    mounted() {
        this.$dom = this.box == 'window' ? window : document.querySelector(this.box);
        if (this.$dom) {
            this.bindHeaderScroll(this.$dom, 200);
        } else {
            this.$dom = null;
        }
    },
    methods: {
        bindHeaderScroll(dom, max = 100) {
            if (isNaN(max) || max < 50) { return }
            const half = max / 2;
            this.scrollFunction = () => {
                const st = this.box == 'window' ? (document.body.scrollTop || document.documentElement.scrollTop) : dom.scrollTop;
                // console.log(st);
                if (st > max) {
                    if (this.header_opacity != 1 || this.header_background == '#ffffff00') {
                        this.header_title = this.title;
                        this.header_color = this.scrollColor;
                        this.header_background = this.scrollBG;
                        this.header_opacity = 1;
                    }
                } else {
                    this.header_opacity = Math.abs(half - st) / half;
                    if (st > half) {//后一半
                        this.header_title = this.title;
                        this.header_color = this.scrollColor;
                        this.header_background = this.scrollBG;
                    } else {//前一半
                        this.header_title = '';
                        this.header_color = this.baseColor;
                        this.header_background = '#ffffff00';
                    }
                }
                this.$emit('callback', st, st > half);
            }
            dom.addEventListener('scroll', this.scrollFunction)
        }
    }
}
</script>
<style scoped></style>